import { log } from './utils/debugHelper';

if (process.env.NODE_ENV === `production` && typeof window !== `undefined`) {
  //log('[analytics] Tracking analytics');
} else {
  log(
    '[analytics] Detected non-production environment, not tracking analytics'
  );
}

// ***** Analytics Labels *****
export const EMPLOYER_LABEL = 'employer';
export const STAFF_LABEL = 'job_seeker';
export const LEAD_LABEL = 'lead';
export const RESUME_LABEL = 'resume';
export const JOB_POSTING_LABEL = 'job_posting';
export const COMBO_LABEL = 'combo';
export const PROFILE_PUBLIC_LABEL = 'public_profile';
export const PROFILE_PRIVATE_LABEL = 'private_profile';
export const DAILY_JOB_ALERT_LABEL = 'daily_job_alert';
export const WEEKLY_JOB_ALERT_LABEL = 'weekly_job_alert';
export const ABLE_TO_APPLY_LABEL = 'able_to_apply';
export const CLOSE_POSTING_LABEL = 'close_posting';

// ***** Analytics Categories *****
export const ENGAGEMENT_CATEGORY = 'engagement';
export const ECOMMERCE_CATEGORY = 'ecommerce';
export const OUTBOUND_CATEGORY = 'outbound';

// ***** Analytics Action Types *****
export const LOGIN_ACTION = 'LOGIN';
export const LOGOUT_ACTION = 'LOGOUT';
export const CREATE_ACCOUNT_ACTION = 'CREATE_ACCOUNT';
export const VIEW_ITEMS_ACTION = 'VIEW_ITEMS';
export const VIEW_JOB_ACTION = 'VIEW_JOB';
export const ADD_TO_CART_ACTION = 'ADD_TO_CART';
export const REMOVE_FROM_CART_ACTION = 'REMOVE_FROM_CART';
export const BEGIN_CHECKOUT_ACTION = 'BEGIN_CHECKOUT';
export const ADD_SHIPPING_INFO_ACTION = 'ADD_SHIPPING_INFO';
export const ADD_PAYMENT_INFO_ACTION = 'ADD_PAYMENT_INFO';
export const PURCHASE_ACTION = 'PURCHASE';
export const VIEW_SEARCH_RESULTS_ACTION = 'VIEW_SEARCH_RESULTS';
export const SUBMIT_APPLICATION_ACTION = 'SUBMIT_APPLICATION';
export const SEND_MESSAGE_ACTION = 'SEND_MESSAGE';
export const PROFILE_VISIBILITY_ACTION = 'PROFILE_VISIBILITY';
export const PROFILE_RELOCATE_ACTION = 'PROFILE_RELOCATE';
export const JOB_ALERT_SUBSCRIPTION_ACTION = 'JOB_ALERT_SUBSCRIPTION';
export const PROFILE_COMPLETION_ACTION = 'PROFILE_COMPLETION';
export const OUTBOUND_LINK_ACTION = 'OUTBOUND_LINK_ACTION';
export const GENERATE_LEAD_ACTION = 'GENERATE_LEAD';
export const ASSET_DOWNLOAD_ACTION = 'DOWNLOAD_ASSET';
export const PROMO_CLICK_ACTION = 'PROMO_CLICK';
export const CLOSE_POSTING_ACTION = 'CLOSE_POSTING';

function getActionName(actionType: string) {
  switch (actionType) {
    case LOGIN_ACTION:
      return {
        gtag: 'login',
        bing: 'login',
      };
    case LOGOUT_ACTION:
      return {
        gtag: 'logout',
      };
    case CREATE_ACCOUNT_ACTION:
      return {
        gtag: 'sign_up',
        bing: 'sign_up',
        fb: 'CompleteRegistration',
      };
    case VIEW_ITEMS_ACTION:
      return {
        gtag: 'view_item_list',
      };
    case VIEW_JOB_ACTION:
      return {
        gtag: 'view_job',
      };
    case ADD_TO_CART_ACTION:
      return {
        gtag: 'add_to_cart',
      };
    case REMOVE_FROM_CART_ACTION:
      return {
        gtag: 'remove_from_cart',
      };
    case BEGIN_CHECKOUT_ACTION:
      return {
        gtag: 'begin_checkout',
        bing: 'begin_checkout',
        fb: 'InitiateCheckout',
      };
    case ADD_SHIPPING_INFO_ACTION:
      return {
        gtag: 'add_shipping_info',
      };
    case ADD_PAYMENT_INFO_ACTION:
      return {
        gtag: 'add_payment_info',
      };
    case PURCHASE_ACTION:
      return {
        gtag: 'purchase',
        bing: 'purchase',
        fb: 'Purchase',
      };
    case VIEW_SEARCH_RESULTS_ACTION:
      return {
        gtag: 'view_search_results',
        bing: 'view_search_results',
      };
    case SUBMIT_APPLICATION_ACTION:
      return {
        gtag: 'submit_application',
        bing: 'submit_application',
        fb: 'SubmitApplication',
      };
    case PROFILE_VISIBILITY_ACTION:
      return {
        gtag: 'profile_visibility',
      };
    case PROFILE_RELOCATE_ACTION:
      return {
        gtag: 'profile_relocate',
      };
    case PROFILE_COMPLETION_ACTION:
      return {
        gtag: 'profile_completion',
      };
    case OUTBOUND_LINK_ACTION:
      return {
        gtag: 'outbound_link',
      };
    case GENERATE_LEAD_ACTION:
      return {
        gtag: 'generate_lead',
      };
    case ASSET_DOWNLOAD_ACTION:
      return {
        gtag: 'download_asset',
      };
    case PROMO_CLICK_ACTION:
      return {
        gtag: 'promo_click',
      };
    case CLOSE_POSTING_ACTION:
      return {
        gtag: 'close_posting',
      };
    default:
      return {
        gtag: 'undefined_action',
      };
  }
}

let googleTagManagerDataLayer =
  typeof window !== 'undefined' && (window['dataLayer'] || []);

const Events = {
  trackPurchase: function ({ transaction_id, value, items }) {
    log('[analytics] trackPurchase', {
      transaction_id,
      value,
      items,
    });
    const transformedItems = items.reduce(
      (acc, item) => ({
        ...acc,
        [item.item_id]: item,
      }),
      {}
    );
    const coupons = items.reduce((acc, item) => {
      if (!!item.coupon) {
        acc.push(item.coupon);
      }
      return acc;
    }, []);

    // Google Tag Manager "data layer"
    // Learn more: https://support.google.com/tagmanager/answer/6107169?hl=en
    const tagManagerPayload = {
      event: 'purchase',
      transaction_id,
      value: value,
      currency: 'USD',
      items: items,
      product: {
        type: !!transformedItems['JOBSUB']
          ? 'Subscription'
          : !!transformedItems['JOB']
            ? 'Premium'
            : !!transformedItems['BASIC']
              ? 'Basic'
              : 'Add-On',
        jobQuantity:
          transformedItems.JOBSUB?.quantity ||
          transformedItems.JOB?.quantity ||
          transformedItems.BASIC?.quantity,
        invQuantity: !!transformedItems.INVSUB
          ? transformedItems.INVSUB?.quantity
          : transformedItems.INV?.quantity,
        natQuantity: transformedItems.NAT?.quantity,
        coupon: [...new Set(coupons)].join(','),
      },
    };
    googleTagManagerDataLayer.push(tagManagerPayload);
  },

  trackEvent: function (props: {
    actionType: string;
    category: string;
    label?: string;
    payload?: {};
  }) {
    log('[analytics] trackEvent', {
      actionType: props.actionType,
      category: props.category,
      label: props.label,
    });
    const action = getActionName(props.actionType);
    // Google Tag Manager "data layer"
    // Learn more: https://www.analyticsmania.com/post/datalayer-push/
    let body = {
      event: action.gtag,
      label: props.label ? props.label : '',
    };
    if (props.payload) {
      body = { ...body, ...props.payload };
    }
    googleTagManagerDataLayer.push(body);
  },
};

const User = {
  setUser: function (props: {
    userId: number;
    email: string;
    role: string;
    name: string;
    position: string;
    specialty: string;
    isPublic: boolean;
    isRelocate?: boolean;
    availability: string;
    tempAvailability: string;
    empType: string;
    userProfileComplete: number;
    userState: string;
    userZipCode: string;
  }) {
    log('[analytics] setUser', props);
    // Google Tag Manager "data layer"
    // Learn more: https://www.analyticsmania.com/post/datalayer-push/
    googleTagManagerDataLayer.push({ event: 'set_user', ...props });
  },
};

export default {
  Events,
  User,
};
